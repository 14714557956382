const Layout = () => import('@/layout/index.vue')

export default {
  name: 'NanoPlus',
  path: '/:language?/nanoPlus',
  component: Layout,
  redirect: '/nanoPlus/index',
  children: [
    {
      name: 'NanoPlusIndex',
      path: 'index',
      component: () => import('@/views/nanoPlus/index.vue'),
      meta: {
        title: 'viaim NanoPlus - Earbuds Singapore',
        hideTab: false,
        menu: 'NanoplusDetail',
        description: 'Experience the ultimate sound quality with our top-rated EARBUDS SINGAPORE. Shop now for the best deals on high-quality earbuds in Singapore',
        image:'https://viaim-h5.oss-ap-southeast-1.aliyuncs.com/iflybuds-website-world/images/nanoplusdetail/new1silverchange.png'
      }
    }
  ]
}
