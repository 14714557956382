<script setup lang="ts">
import router from '@/router'
const props = defineProps<{
  text?: string
  type?: string
  path?: string
  nopointer?: boolean
}>()

const onPage = () => {
  if (props.path) router.push(props.path)
}
</script>

<template>
  <div
    class="btn"
    :class="{ black: type === 'black', white: type === 'white', grey: type === 'grey', gold: type === 'gold', 'white-line': type === 'white-line', pointer: !nopointer }"
    @click="onPage"
  >
    <div>
      <span>{{ text }}</span>
      <svg class="iconpark-icon">
        <use href="#Property1Variant4"></use>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  display: inline-block;
  padding: 1rem 0.8rem 1rem 2rem;
  height: 4rem;
  gap: 0.4rem;
  border-radius: 2rem;
  user-select: none;
  border: 1px solid #ababab;
  box-sizing: border-box;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 1.4rem;
    }
    .iconpark-icon {
      width: 2rem;
      height: 2rem;
      margin-left: 0.4rem;
    }
  }
}

.pointer {
  cursor: pointer;
}

.btn:active {
  background: rgba(0, 0, 0, 0.12);
}

.black {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.black:active {
  background: rgba(0, 0, 0, 0.8);
}

.white {
  background: #fff;
  color: #000;
  border: 1px solid #ababab;
}
.grey {
  background: #424242;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.04);
}
.gold {
  background: #C2B3A3;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.04);
}

.white-line {
  border: 1px solid #fff;
  color: #fff;
}
</style>
