/**
 * @param title 标题，默认 未来智能
 * @param hideTab 是否隐藏标签页，默认 false
 * @param menu 当前菜单
 * @param custom 自定义标题，用于动态路由标题，默认false
 * @param back 首页是否显示返回按钮，默认false
 */

import { createRouter, createWebHashHistory } from 'vue-router'
import { useMenuStore } from '@/store/menu'
import { useInternationalStore } from '@/store/international'

const Layout = () => import('@/layout/index.vue')

// 导入静态路由
const modules: Record<string, any> = import.meta.glob(['./modules/**/*.ts'], {
  eager: true
})

// 原始静态路由
const routes: any[] = [
  {
    path: '/:language?',
    name: 'layout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: 'viaim Instant Translation AI Headset',
          description: 'Experience seamless communication with the viaim AI headset. The perfect companion for travelers and professionals. Get yours now!'
        }
      }
    ]
  }
]

Object.keys(modules).forEach((key) => {
  routes.push(modules[key].default)
})

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

const customTitle = (path: string) => {
  let title = ''
  switch (path) {
    case '/support/product/nanoAir':
      title = 'viaim Air Support'
      break
    case '/support/product/nanoPlus':
      title = 'viaim Nano+ Support'
      break
    case '/support/product/recdot':
      title = 'viaim RecDot Support'
      break
    default:
      title = 'viaim'
  }
  return title
}

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next('/')
  } else {
    const menuStore = useMenuStore()
    const internationalStore = useInternationalStore()
    menuStore.SetMenuSelect(to.meta.menu ?? '')
    if (to.params.language !== '') next()
    else next(`/${internationalStore.location.abb}${to.fullPath}`)
  }
})

router.afterEach((to) => {
  window.scrollTo({ top: 0 })
  const description: any = document.getElementById('shareContent')
  description.content = to.meta.description || 'viaim'
  const title: any = document.getElementById('title')
  if (to.meta.custom) title.innerText = customTitle(to.path)
  else title.innerText = to.meta.title || 'viaim'
  const image: any = document.getElementById('shareImage')
  if (to.meta.image) image.content = to.meta.image || 'https://viaim-h5.oss-ap-southeast-1.aliyuncs.com/iflybuds-website-world/images/logo.png'
  else image.content = 'https://viaim-h5.oss-ap-southeast-1.aliyuncs.com/iflybuds-website-world/images/logo.png'
})

export default router
