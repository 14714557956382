const Layout = () => import('@/layout/index.vue')

export default {
  name: 'Air2',
  path: '/:language?/air2',
  component: Layout,
  redirect: '/air2/index2',
  children: [
    {
      name: 'AirIndex2',
      path: 'index2',
      component: () => import('@/views/air/index.vue'),
      meta: {
        title: 'viaim AIR- Wireless Earphones Singapore',
        hideTab: false,
        menu: 'AirDetail',
        description: 'Experience the ultimate convenience and freedom with our top-of-the-line WIRELESS EARPHONES SINGAPORE. Shop now for the best deals!',
        image:'https://viaim-h5.oss-ap-southeast-1.aliyuncs.com/iflybuds-website-world/images/airdetail/bannerair.png'
      }
    }
  ]
}
