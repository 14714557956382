export default {
  name: 'ExternalArticleTerms',
  path: '/:language?/external/articleTerms/:id?',
  component: () => import('@/views/external/articleTerms/index.vue'),
  meta: {
    title: 'Legal | viaim',
    hideTab: false,
    menu: '',
    description: "Before using viaim's services, please read carefully."
  }
}