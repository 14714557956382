const Layout = () => import('@/layout/index.vue')

export default {
  name: 'Viaim',
  path: '/:language?/viaimai',
  component: Layout,
  redirect: '/viaimai/index',
  children: [
    {
      name: 'ViaimaiIndex',
      path: 'index',
      component: () => import('@/views/viaimai/Index.vue'),
      meta: {
        title: 'viaim AI',
        hideTab: false,
        menu: 'Viaimai',
        description: 'Going the extra mile for you, helping you excel.'
      }
    }
  ]
}
